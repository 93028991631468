import { tw } from "@/client/lib/tw-tagged-literal";
import sitemap from "@/shared/sitemap";
import React, { FunctionComponent, useContext } from "react";
import { useTranslation } from "react-i18next";
import { InfoBox } from "../../elements/info-box";
import { CwsInput } from "./cws-input";
import { HeadlineInput } from "./headline-input";
import { TagsInput } from "./tags-input";
import {
    selectEditingTransparentShare,
    selectProjectHandle,
    selectShareOfPostId,
} from "./reducer";
import { useAppSelector } from "./redux-hooks";
import AttachmentComposer from "./attachment-composer";
import { AttachmentComposerContext } from "./attachment-composer-context";
import { ContentEditor } from "./content-editor";

export const EditingPanel: FunctionComponent = () => {
    const { t } = useTranslation();

    const editingTransparentShare = useAppSelector(
        selectEditingTransparentShare
    );
    const shareOfPostId = useAppSelector(selectShareOfPostId);
    const projectHandle = useAppSelector(selectProjectHandle);
    const attachmentComposerRef = useContext(AttachmentComposerContext);

    return (
        <div className="flex flex-col gap-3 py-3">
            {editingTransparentShare && shareOfPostId ? (
                <InfoBox className="!m-3 !w-auto" level="post-box-info">
                    <div className={tw`co-prose prose prose-sm`}>
                        <p>
                            Shares without content can't have content added
                            after posting. You <b>can</b> edit the tags.
                        </p>
                        <p>
                            If you want, you can{" "}
                            <a
                                href={sitemap.public.project
                                    .composePost({
                                        projectHandle: projectHandle,
                                        shareOfPostId: shareOfPostId,
                                    })
                                    .toString()}
                                target="_blank"
                                rel="noreferrer"
                            >
                                share this post again
                            </a>{" "}
                            and add your content there.
                        </p>
                    </div>
                </InfoBox>
            ) : (
                <>
                    <HeadlineInput />
                    <ContentEditor />
                    <AttachmentComposer ref={attachmentComposerRef} />
                </>
            )}
            <TagsInput />
            <CwsInput />
        </div>
    );
};
